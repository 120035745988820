import React, { useRef, useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import LoadingIndicator from '../../styles/LoadingIndicator'

export interface BasicProps {
  value?: string;
  height?: number;
  onChange: (a: string, editor: TinyMCEEditor) => void;
}

export default function Basic({ value, height, onChange }: BasicProps) {
  height = height || 500
  const [loading, setLoading] = useState<boolean>(true)
  const containerRef = useRef(null)
  const editorRef = useRef(null)
  const sheets = $('link[rel="stylesheet"]').map(function (i, ele) {
    return $(this).attr('href')
  })

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        containerRef.current.classList.remove('d-none')
      }, 300)
    }
  }, [loading])
  
  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{ height }}>
      {loading && (
        <LoadingIndicator />
      )}
      <div ref={containerRef} className='d-none w-100'>
        <Editor
          apiKey='q48smcaohc70j5aqvsbyw3ecgnoupoaewygijdvo0xarjx5u'
          onInit={(evt, editor) => {
            editorRef.current = editor
            setLoading(false)
          }}
          { ...(!!value && { value }) }
          onEditorChange={onChange}
          init={{
            width: '100%',
            height,
            content_css : sheets.toArray(),
            plugins: [
              'autolink', 'lists', 'link', 'preview',
              'anchor', 'searchreplace', 'fullscreen'
            ],
            toolbar: 'blocks | ' +
              'bold italic | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help'
          }}
        />
      </div>
    </div>
  )
}