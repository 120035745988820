import { Artifact, ArtifactsMap } from "./";

export default class ArtifactRegistry { 
  _emit: (artifact: Artifact) => void;
  _artifacts: ArtifactsMap;

  constructor() {
    this._emit = null;
    this._artifacts = {};
  }

  get artifacts(): ArtifactsMap {
    return this._artifacts;
  }

  registerArtifact(name: string, artifact: Artifact, emit = true): void {
    if (this._artifacts[name]) {
      return;
    }

    this._artifacts = { 
      ...this._artifacts, 
      ...({ [name]: artifact } as { [x: string]: Artifact; }) 
    } as ArtifactsMap;

    if (emit && this._emit) {
      this._emit(artifact);
    }
  }

  registerArtifacts(artifacts: ArtifactsMap): void {
    Object.keys(artifacts).forEach(key => 
      this.registerArtifact(key, artifacts[key], false)
    );
    if (this._emit) {
      this._emit(null);
    }
  }

  registerListener(listener: (artifact: Artifact | null) => void): void {
    this._emit = listener;
  }
}