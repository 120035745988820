import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Account } from '../../../accounts/types'
import { User } from '../../../users/types'
import { Property, Project, DraftingProject } from '../../../projects/types'
import { property } from 'lodash'

export const errors = {
  unauthorized: "Something went wrong, please try again.",
  existing_drafting_project: "Unfortunately, this property cannot be added to the list. Please contact your account representative for assistance."
}

export function getError(status, error) {
  return (status == 401 || !errors[error])
    ? errors.unauthorized 
    : errors[error]
}

export const api = createApi({
  reducerPath: 'adminAccountsApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getAccounts: builder.query<Account[], void>({
      query: () => '/admin/accounts',
    }),
    saveAccount: builder.mutation<Account, {
      account?: Account,
      authenticity_token?: string
    }>({
      async queryFn(
        { account: { id, ...account }, authenticity_token },
        queryApi,
        extraOptions,
        fetchWithBaseQuery
      ) {
        return fetchWithBaseQuery({
          url: `/admin/accounts${(id) ? `/${id}` : ''}`,
          method: (id) ? 'PUT' : 'POST',
          body: {
            account: {
              ...account,
              ...(!!account.user && { user_attributes: account.user })
            },
            authenticity_token
          }
        })
      },
    }),
    saveProperty: builder.mutation<Project, { account?: Account, property?: Property, authenticity_token?: string }>({
      async queryFn(
        { account: { account_slug }, property: { id, ...property }, authenticity_token },
        queryApi,
        extraOptions,
        fetchWithBaseQuery
      ) {
        return fetchWithBaseQuery({
          url: `accounts/${account_slug}/properties${(id) ? `/${property.address_slug}` : ''}`,
          method: (id) ? 'PUT' : 'POST',
          body: {
            property,
            authenticity_token
          }
        })
      }
    }),
    updateProject: builder.mutation<Project, { account?: Account, project?: Project, authenticity_token?: string }>({
      async queryFn(
        { account: { id: accountId }, project: { id, ...project }, authenticity_token },
        queryApi,
        extraOptions,
        fetchWithBaseQuery
      ) {
        return fetchWithBaseQuery({
          url: `admin/accounts/${accountId}/projects/${id}`,
          method: 'PUT',
          body: {
            project: {
              ...project,
              ...(!!project.drafting_project && {
                drafting_project_attributes: project.drafting_project
              }),
            },
            authenticity_token
          }
        })
      }
    }),
  }),
})

export const {
  reducer,
  middleware,
  useGetAccountsQuery,
  useSavePropertyMutation,
  useSaveAccountMutation,
  useUpdateProjectMutation
} = api