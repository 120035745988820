import React, { useEffect, useRef, useState, useContext } from 'react'
import { Property as IProperty } from '../../../../projects/types'
import { ModalProps } from './'
import { Form as SearchForm } from '@active-geocoder/components/AddressSearch'
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import formatAddress from '../../../../portfolio/lib/formatAddress'
import { useSelector, useActor } from '@xstate/react'
import Context from './Context'
import { useDeepCompareEffect } from 'react-use'
import { useSavePropertyMutation, getError } from '../../slices/api'

export interface PropertyProps {
  property?: IProperty;
  onSubmit?: (state: ModalProps)=> void;
}

const selectAccount = (state) => state.context.account
const selectProperty = (state) => state.context.property
const selectReadOnly = (state) => state.context.readOnly

export default function Property({ onSubmit }: ModalProps) {

  const initialState = {
    address: null,
    city: null,
    state: null,
    zip_code: null,
    formatted_address: null,
    exist_unit_ct: null
  }
  const searchRef = useRef(null)
  const { service, authenticity_token } = useContext(Context)
  const account = useSelector(service, selectAccount)
  const property = useSelector(service, selectProperty) || initialState
  const readOnly = useSelector(service, selectReadOnly)
  const [current, send] = useActor(service)
  const [state, setState] = useState<IProperty>((property.address)
    ? { ...property, formatted_address: formatAddress(property) }
    : property
  )

  const [saveProperty, {
    isLoading,
    isUninitialized,
    isSuccess,
    isError,
    data,
    reset,
    error: initialError
  }] = useSavePropertyMutation()

  const { data: error, status } = {} = initialError as any || {}

  useDeepCompareEffect(() => {
    setState({
      ...property,
      formatted_address: formatAddress(property)
    })
  }, [property])

  useEffect(() => {
    if (!isLoading && !isUninitialized && isSuccess && data) {
      send({ type: 'RESUME', project: data })
      reset()
      if (onSubmit) {
        onSubmit()
      }
    }
  }, [
    isLoading,
    isUninitialized,
    isSuccess,
    data
  ])

  function handleChange(e) {
    const { name, value } = e.target
    setState({
      ...state,
      [name]: value
    })
  }

  function handlePlaceChange(place) {
    const { formatted_address } = JSON.parse(atob(place.replace('google://', ''))) 
    setState({
      exist_unit_ct: null,
      formatted_address,
      place
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    saveProperty({ account, property: state, authenticity_token })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='py-4 gy-3'>
        <Col xs={8}>
          <strong>Address</strong>
          {(readOnly)
            ? (
              <Form.Control
                value={formatAddress(property)}
                disabled
              />
            ) : (
              <SearchForm.Control
                ref={searchRef}
                name='place'
                placeholder='123 Main St'
                lookup='google'
                onChange={handlePlaceChange}
                value={state.formatted_address}
              />
            )}
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Exist Unit Ct</strong>
            <Form.Control
              type='number'
              name='exist_unit_ct'
              placeholder='100'
              value={state.exist_unit_ct || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        {!!isError && (
          <Col xs={12} className='text-center text-danger'>
            <small>{getError(status, error)}</small>
          </Col>
        )}
        <Col xs={12} className='d-flex justify-content-end'>
          <Button
            className='d-flex align-items-center'
            variant='primary'
            type='submit'
          >
            {isLoading && (
              <Spinner
                className='me-2 text-white'
                animation='border'
                size='sm'
              />
            )}
            <span>Save</span>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}