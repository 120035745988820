import { ReduxReducer, SagaGenerator } from "./";
import { ReducerRegistry, MiddlewareRegistry, SagaRegistry } from "./store";

export default function useRegistry(
  reducers: Record<string, ReduxReducer> = null,
  middleware: Record<string, ReduxReducer> = null,
  sagas: Record<string, () => SagaGenerator> = null
): void {
  if (reducers) {
    ReducerRegistry.registerArtifacts(reducers);
  }
  if (middleware) {
    MiddlewareRegistry.registerArtifacts(middleware);
  }
  if (sagas) {
    Object.keys(sagas).forEach(key => 
      SagaRegistry.registerArtifact(key, sagas[key])
    );
  }
}