import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, Middleware } from "redux";
import createSagaMiddleware, { Saga, SagaMiddleware } from "redux-saga";
import ArtifactRegistry from "./ArtifactRegistry";
import defaultReducer from "./defaultSlice";
import { ReduxCombined } from "./";
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares'

const sagaMiddleware: SagaMiddleware<Saga> = createSagaMiddleware();

export const ReducerRegistry = new ArtifactRegistry();
export const MiddlewareRegistry = new ArtifactRegistry();

ReducerRegistry.registerArtifact("app", defaultReducer);

const opts = { immutableCheck: false, serializableCheck: false };
export const store = configureStore({
  reducer: ReducerRegistry.artifacts as ReduxCombined,
  middleware: (getDefaultMiddleware: (opts: any) => Middleware[]) => {
    const middleware = getDefaultMiddleware(opts).concat(sagaMiddleware);
    middleware.push(dynamicMiddlewares);
    return middleware;
  }
});

ReducerRegistry.registerListener(() => {
  const reducers = ReducerRegistry.artifacts as ReduxCombined;
  store.replaceReducer(combineReducers(reducers));
});

MiddlewareRegistry.registerListener(() => {
  const middleware = MiddlewareRegistry.artifacts as Record<string, Middleware>;
  Object.keys(middleware).forEach(key => addMiddleware(middleware[key]));
});

export const SagaRegistry = new ArtifactRegistry();
SagaRegistry.registerListener((saga: Saga) => 
  sagaMiddleware.run(saga)
);