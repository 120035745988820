import React, { useState } from 'react'
import { start } from "@active-geocoder/AddressSearch";
import { registerComponents } from "../lib/react";
import Share from '../components/application/share'

import ModuleLoader from '../redux/ModuleLoader'
import { ThemeProvider } from 'styled-components'
import * as middleware from '../components/admin/accounts/slices/middleware'
import * as reducers from '../components/admin/accounts/slices/reducers'
import theme from '../styles/theme'
import Modal, { ModalProps } from '../components/admin/accounts/common/Modal'
import { Button } from 'react-bootstrap'

interface AccountModal extends ModalProps {
  variant?: string;
}

function AccountModal(props: AccountModal) {

  const [show, setShow] = useState<boolean>(false)

  function handleHide() {
    window.location.reload()
  }

  return (
    <ModuleLoader reducers={reducers} middleware={middleware} >
      <ThemeProvider theme={theme}>
        <Modal show={show} onHide={handleHide} { ...props } />
        <Button
          variant={props.variant || 'secondary'}
          className={props.className}
          onClick={()=> setShow(!show)}
        >
          Setup
        </Button>
      </ThemeProvider>
    </ModuleLoader>
  )
}

registerComponents({
  Share,
  AccountModal
});

function initAddressSearch() {
  start();
}

document.addEventListener("DOMContentLoaded", initAddressSearch);
