import { Modal as TwbsModal } from 'react-bootstrap'
import styled, { StyledComponent } from 'styled-components'

const Modal: StyledComponent = styled(TwbsModal)`
  .modal-dialog {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    &:not(.modal-dialog-centered) {
      margin: 7.5rem auto !important;
    }
  }
`

export default Modal