import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useActor, useSelector } from '@xstate/react'
import FormCheck from '../../../../../styles/FormCheck'
import { Account as IAccount } from '../../../../accounts/types'
import {
  useGetAccountsQuery, useSaveAccountMutation
} from '../../slices/api'
import { ModalProps } from './'
import Context from './Context'

const selectAccount = (state) => state.context.account

export default function Account({ onSubmit }: ModalProps) {

  const { service, authenticity_token } = useContext(Context)
  const account = useSelector(service, selectAccount)
  const [current, send] = useActor(service)
  const [formFor, setFormFor] = useState<string>((account?.id) ? 'EXISTING' : 'NEW')
  const [state, setState] = useState<IAccount>({
    account_slug: null,
    company: null,
    domain: null,
    verified_email: null,
    role_broker: false,
    role_rental_owner: false,
    role_homeowner: false,
  })

  const [saveAccount, {
    isLoading,
    isUninitialized,
    isSuccess,
    data,
    reset
  }] = useSaveAccountMutation()

  const { data: accounts = [] } = useGetAccountsQuery()

  useEffect(() => {
    if (!isLoading && !isUninitialized && isSuccess && data) {
      send({ type: 'RESUME', account: data })
      setFormFor('EXISTING')
      reset()
      if (onSubmit) {
        onSubmit()
      }
    }
  }, [
    isLoading,
    isUninitialized,
    isSuccess,
    data
  ])
  
  function handleFormForChange(e) {
    setFormFor(e.target.value)
    send(e.target.value)
  }

  function handleChange(e) {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  function handleCheck(e) {
    const { name, checked } = e.target
    setState({ ...state, [name]: checked })
  }


  function handleSubmit(e) {
    e.preventDefault()
    saveAccount({ account: state, authenticity_token })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='py-4'>
        <Col xs={12}>
          <Form.Group>
            <FormCheck
              inline
              type='radio'
              label='Create New'
              name='formFor'
              value='NEW'
              checked={formFor == 'NEW'}
              onChange={handleFormForChange}
            />
            <FormCheck
              inline
              type='radio'
              label='Existing'
              value='EXISTING'
              checked={formFor == 'EXISTING'}
              onChange={handleFormForChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {formFor == 'NEW' && (
        <Row className='gy-4'>
          <Col xs={12}>
            <Form.Group>
              <strong>Account Slug</strong>
              <Form.Control
                name='account_slug'
                placeholder='socal-invest-1a2b3c'
                value={state.account_slug || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <strong>Company</strong>
              <Form.Control
                name='company'
                placeholder='Company'
                value={state.company || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <strong>Domain</strong>
              <Form.Control
                name='domain'
                placeholder='example.com'
                value={state.domain || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <strong>Verified Email</strong>
              <Form.Control
                name='verified_email'
                placeholder='example@email.com'
                value={state.verified_email || ''}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <FormCheck
                type='checkbox'
                label='Role Broker'
                name='role_broker'
                checked={state.role_broker}
                onChange={handleCheck}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <FormCheck
                type='checkbox'
                label='Role Rental Owner'
                name='role_rental_owner'
                checked={state.role_rental_owner}
                onChange={handleCheck}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group>
              <FormCheck
                type='checkbox'
                label='Role Homeowner'
                name='role_homeowner'
                checked={state.role_homeowner}
                onChange={handleCheck}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className='d-flex justify-content-end'>
            <Button
              className='d-flex align-items-center'
              variant='primary'
              type='submit'
            >
              {isLoading && (
                <Spinner
                  className='me-2 text-white'
                  animation='border'
                  size='sm'
                />
              )}
              <span>Save</span>
            </Button>
          </Col>
        </Row>
      )}
      {formFor == 'EXISTING' && (
        <Row className='gy-3'>
          <Col xs={12}>
            <Form.Select>
              <option>
                {account?.company}
              </option>
              {accounts.filter((a: IAccount) => a.id != account?.id ).map((account: IAccount, i: number) => (
                <option
                  key={`account-${i}`}
                  onClick={() => send({ type: 'SELECT', account })}
                >
                  {account.company}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={12}>
            {account && account.edit_url && (
              <a href={account.edit_url} target='_blank'>
                <strong>{account.edit_url}</strong>
              </a>
            )}
          </Col>
        </Row>
      )}
    </Form>
  )
}