import { DeepPartial, CaseReducerActions } from '@reduxjs/toolkit'
import { ReduxPartial, ReduxActionWritable } from './'
import { createSlice } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

export type DefaultPartial = DeepPartial<{ 
  [x: string]: DefaultSlice;
}>;

export interface DefaultSlice {
  modals?: Record<string, unknown>;
}

export type DefaultActions = CaseReducerActions<{
  setModals: ReduxActionWritable;
}, 'app'>

const initialState: DefaultSlice = {
  modals: {}
}

export const defaltSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModals: (state: ReduxPartial, action: AnyAction) => {
      state.modals = {
        ...state.modals,
        ...action.payload.modals
      }
    },
  },
})

export const { setModals }: DefaultActions = defaltSlice.actions

export const selectModals = (state: DefaultPartial):  Record<string, unknown>  => state.app.modals

export default defaltSlice.reducer