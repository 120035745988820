import React, { useState, useCallback } from 'react'
import { Button, InputGroup, Form, DropdownButton as TwbsDropdownButton } from 'react-bootstrap'
import SuccessIndicator from '../SuccessIndicator'
import styled, { StyledComponent } from 'styled-components'

const DropdownButton: StyledComponent = styled(TwbsDropdownButton)`
  .dropdown-toggle::after {
    border: none;
  }
  .dropdown-menu.show{
    width: 100vw;
    @media (min-width: 768px) {
      width: 60vw;
    }
    @media (min-width: 992px) {
      width: 50vw;
    }
    @media (min-width: 1200px) {
      width: 40vw;
    }
    @media (min-width: 1400px) {
      width: 30vw;
    }
  }
`

export interface ShareProps {
  className?: string;
}

export default function Share({ className }: ShareProps) {

  const [show, setShow] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  const ref = useCallback((node) => {
    if (!node) {
      return
    }

    const handleClickOutside = (e) => {
      if (!node.contains(e.target)) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)

  }, [])

  function handleClick(e) {
    e.stopPropagation()
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
        setShow(false)
      }, 1500)
    })
    document.execCommand("copy")
  }
  

  return (
    <DropdownButton
      ref={ref}
      variant='primary'
      title='Share'
      show={show}
      className={className}
      onClick={() => setShow(!show)}
      autoClose={false}
    >
      <InputGroup className="px-2">
        <Button
          variant="outline-primary"
          className='d-flex align-items-center'
          onClick={handleClick}
        >
          {(copied)
            ? (
              <>
                <SuccessIndicator className='pe-2' />
                <small>Copied!</small>
              </>
            ) : (
              <small>Copy</small>
            )}
        </Button>
        <Form.Control
          value={window.location.href}
          onClick={handleClick}
          disabled
        />
      </InputGroup>
    </DropdownButton>
  )
}