import React, { useState, useEffect, ChangeEvent, forwardRef, MutableRefObject, useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { usePrevious } from 'react-use';

export interface MoneyControlProps {
  name?: string;
  placeholder?: string;
  value?: number;
  onChange?: (value: number) => void;
}

const MoneyControl = forwardRef(({ name, placeholder, value, onChange }: MoneyControlProps,  ref: MutableRefObject<HTMLDivElement>) => {
  
  const controlRef = useRef(null)
  const formatter = new Intl.NumberFormat()
  const [state, setState] = useState<string>(value?.toString() || '')
  const prevState = usePrevious(state)

  useEffect(() => {
    setState(value?.toString() || '')
  }, [value])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    const num = (value.length > 0) ? parseInt(value.replace(/[^0-9.-]+/g,"")) : 0

    if (!isNaN(num)) {
      setState((!isNaN(num)) ? (num) ? num.toString() : '' : '')
    }
    if (onChange) {
      onChange((!isNaN(num) ? num : 0))
    }
  }

  function handleKeyDown(e) {
    if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault()
    }
  }

  function handleClick(e) {
    const pos = (state?.length) ? state.length * 2 : 0
    controlRef.current.setSelectionRange(pos, pos)
  }

  return (
    <InputGroup ref={ref}>
      <InputGroup.Text id="currency-addon">$</InputGroup.Text>
      <Form.Control
        ref={controlRef}
        name={name}
        placeholder={placeholder}
        value={(state) ? formatter.format(parseInt(state)) : ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        aria-describedby="currency-addon"
      />
    </InputGroup>
  )
})

export default MoneyControl