export default class Base {
  data: Record<string, any>;
  protocol: string;

  constructor(data: Record<string, any>, protocol: string) {
    this.data = data;
    this.protocol = protocol;
  }

  encode(): string {
    const encoded = btoa(JSON.stringify(Object.getOwnPropertyNames(this.constructor.prototype)
      .map(key => [key, Object.getOwnPropertyDescriptor(this.constructor.prototype, key)])
      .filter(([key, descriptor]: any[]) => typeof descriptor.get === "function")
      .map(([key]) => key as string)
      .reduce((prev, curr) => {
        prev[curr] = this[curr];
        return prev;
      }, {})));
    
    return `${this.protocol}://${encoded}`;
  }
}