import React from "react";
import { Provider } from "react-redux";
import { ReduxReducer, SagaGenerator } from "./";
import useRegistry from "./useRegistry";
import { store } from "./store";

export interface ModuleLoaderProps {
  reducers?: Record<string, ReduxReducer>;
  middleware?: Record<string, ReduxReducer>;
  sagas?: Record<string, () => SagaGenerator>;
  children?: React.ReactElement | React.ReactElement[];
}

const ModuleLoader: React.FC<ModuleLoaderProps> = ({ reducers, middleware, sagas, children }: ModuleLoaderProps) => {
  useRegistry(reducers, middleware, sagas);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};
export default ModuleLoader;