import Lookup from "./Lookup";

const lookups = ["google"];

let started = false;

export function start(): void {
  if (!started) {
    started = true;

    Promise.all(lookups.map(lookup => new Lookup(lookup)));
  }
}