import React, { ChangeEvent, forwardRef, useState, useEffect, MutableRefObject } from "react";
import { Form } from "react-bootstrap";
import Google from "./lookups/Google";

export enum Lookup {
  google="google"
}

export const Services = {
  [Lookup.google]: Google  
}

export interface ControlProps {
  className?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  lookup?: string;
  autoFocus?: boolean;
  required?: boolean;
  onChange?: (place: string) => void; 
}

const Control = forwardRef(
  ({ className, placeholder, name, value, lookup, autoFocus, required, onChange }: ControlProps, ref: MutableRefObject<HTMLInputElement>
) => {

  const [address, setAddress] = useState<string>("");
  const [place, setPlace] = useState<string>(null);

  useEffect(() => {
    if (Services[lookup]) {
      new Services[lookup](ref, setAddress, setPlace)
    }
  }, []);

  useEffect(() => {
    setAddress(value);
  }, [value]);

  useEffect(() => {
    if (place) {
      onChange(place);
    }
  }, [place]);

  function handleChange(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    setAddress(target.value);
  }

  function handleBlur(e) {
    if (!place) {
      setAddress("")
    }
  }

  return (
    <>
      <Form.Control
        ref={ref}
        value={address || ""}
        className={className}
        placeholder={placeholder}
        autoFocus={autoFocus}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Form.Control
        type="hidden"
        name={name}
        value={place || ""}
        className={className}
        placeholder={placeholder}
      />
    </>
  );
});

Control.displayName = "Control";

export default Control;
