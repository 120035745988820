import { MutableRefObject } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import Result from "../../../AddressSearch/results/Google";

export default class Google {

  ref: MutableRefObject<HTMLInputElement>;
  setAddress: (address: string) => void;
  setPlace: (place: string) => void;

  constructor(ref: MutableRefObject<HTMLInputElement>, setAddress: (address: string) => void, setPlace: (place: string) => void) {
    this.ref = ref;
    this.setAddress = setAddress;
    this.setPlace = setPlace;

    this.process = this.process.bind(this);

    const loader = new Loader({
      apiKey: 'AIzaSyAWK9L6fqFIhTf6hwsnMO8gRCLFnvgvnxU',
      version: "weekly",
      libraries: ["places", "geometry", "drawing"]
    });

    loader.load().then(this.process);
  }

  process(google: any): void {
    const autocomplete = new google.maps.places.Autocomplete(this.ref.current, {types: ["address"]});
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const result = new Result(place);
      this.setAddress(result.formatted_address);
      this.setPlace(result.encode());
    });
  }
}