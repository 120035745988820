/* eslint @typescript-eslint/explicit-module-boundary-types: "off" */

import React from "react";
import { render, hydrate } from "react-dom";

const CLASS_NAME_ATTR = "data-react-class";
const PROPS_ATTR = "data-react-props";
const RENDER_ATTR = "data-hydrate";

export function mountComponents(context) {
  return function () {
    const keys = Object.keys(context);

    for (const key of keys) {
      const selector = `div[${CLASS_NAME_ATTR}="${key}"]`;
      const nodes = document.querySelectorAll(selector);

      for (let i = 0; i < nodes.length; ++i) {
        const node = nodes[i];
        const component = context[key];
        const constructor = component.__esModule ? context[key].default : context[key];
        const propsJson = node.getAttribute(PROPS_ATTR);
        const props = propsJson && JSON.parse(propsJson);
        const doHydrate = node.getAttribute(RENDER_ATTR);

        if (!constructor) {
          const message = `Cannot find component in current context: "${key}"`;

          if (console && console.log) {
            console.log(`%c[react-rails] %c${message} for element,`, "font-weight: bold", "font-weight: normal", node);
            console.log("%c[react-rails] %cCurrent context:", "font-weight: bold", "font-weight: normal", context);
          }

          throw new Error(`${message}. Make sure your component is available to render.`);
        }

        const reactComponent = React.createElement(constructor, props);

        if (doHydrate && typeof hydrate === "function") {
          hydrate(reactComponent, node);
        } else {
          render(reactComponent, node);
        }
      }
    }
  };
}

export function registerComponents(context) {
  document.addEventListener("DOMContentLoaded", mountComponents(context));
  document.addEventListener("turbolinks:load", mountComponents(context));
  document.addEventListener("ComponentMount", mountComponents(context));
}