import Google from "./lookups/Google";

export default class Lookup {
  lookup: string;
  
  classMap = {
    "google": Google
  };

  constructor(lookup: string) {
    this.lookup = lookup;
    this.initService();
  }

  initService(): void {
    new this.classMap[this.lookup]();
  }
}