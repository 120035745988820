import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import useFormattedPhone from '../../hooks/useFormattedPhone'
import { Row, Col, Form } from 'react-bootstrap'

export interface PhoneControlProps {
  number?: string;
  onChange?: (number: string) => void;
}

export default function PhoneControl({ number, onChange }: PhoneControlProps) {

  const { parts = [null, null, null] } = useFormattedPhone(number)
  const [phone, setPhone] = useState<string[]>(parts)
  const phoneRefs = Array.from(Array(3), () => useRef(null))

  useEffect(() => {
    setPhone(parts)
  }, [parts])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target
    if (value.length && isNaN(parseInt(value))) {
      return
    }
    const i = name.match(/\[[^\[\]]*\]/g).length - 1
    const state = [ ...phone ]
    state[i] = value
    if (i < 2) {
      if (!value.length || value.length <= 3) {
        setPhone(state)
      }
      if (value.length == 3) {
        phoneRefs[i].current.blur()
        phoneRefs[i + 1].current.focus()
      }
    }
    else if (value.length <= 4) {
      setPhone(state)
    }

    let n = ''
    switch (state.length) {
      case 1:
        n = `(${state[0]})`
        break;
      case 2:
        n = `(${state[0]}) ${state[1]}`
        break;
      case 3:
        n = `(${state[0]}) ${state[1]}-${state[2]}`
        break;
    }

    if (n.length && onChange) {
      onChange(n)
    }
  }

  return (
    <Row className='align-items-center gx-1'>
      <Col className='flex-grow-0'>
        <span className='hfs-5 text-light'>{'('}</span>
      </Col>
      <Col className='flex-grow-0'>
        <Form.Control
          ref={phoneRefs[0]}
          style={{ width: '75px' }}
          className='text-center'
          name="phone[]"
          placeholder="415"
          value={phone[0]|| ""}
          onChange={handleChange}
          autoFocus
        />
      </Col>
      <Col className='flex-grow-0'>
        <span className='hfs-5 text-light'>{')'}</span>
      </Col>
      <Col className='flex-grow-0'>
        <Form.Control
          ref={phoneRefs[1]}
          style={{ width: '75px' }}
          className='text-center'
          name="phone[][]"
          placeholder="123"
          value={phone[1]|| ""}
          onChange={handleChange}
        />
      </Col>
      <Col className='flex-grow-0'>
        <span className='hfs-5 text-light'>-</span>
      </Col>
      <Col className='flex-grow-0'>
        <Form.Control
          ref={phoneRefs[2]}
          style={{ width: '75px' }}
          className='text-center'
          name="phone[][][]"
          placeholder="4567"
          value={phone[2] || ""}
          onChange={handleChange}
        />
      </Col>
    </Row>
  )
}