import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDeepCompareEffect } from 'react-use'
import { useActor, useSelector } from '@xstate/react'
import FormCheck from '../../../../../styles/FormCheck'
import { Account as IAccount } from '../../../../accounts/types'
import PhoneControl from '../../../../application/PhoneControl'
import { useSaveAccountMutation } from '../../slices/api'
import { ModalProps } from './'
import Context from './Context'

const selectAccount = (state) => state.context.account
const selectUser = (state) => state.context.user

export default function User({ onSubmit }: ModalProps) {

  const { service, authenticity_token } = useContext(Context)
  const account = useSelector(service, selectAccount)
  const user = useSelector(service, selectUser) || {
    first_name: null,
    last_name: null,
    phone: null,
    email: null
  }
  const [current, send] = useActor(service)
  const [formFor, setFormFor] = useState<string>((user?.id) ? 'EXISTING' : 'NEW')
  const [state, setState] = useState<IAccount>({ ...account, user: { ...user }})

  const [saveAccount, {
    isLoading,
    isUninitialized,
    isSuccess,
    data,
    reset
  }] = useSaveAccountMutation()

  useDeepCompareEffect(() => {
    setState({ ...account, user: { ...user }})
  }, [account, user])

  useEffect(() => {
    if (!isLoading && !isUninitialized && isSuccess && data) {
      send({ type: 'RESUME', account: data })
      setFormFor('EXISTING')
      reset()
      if (onSubmit) {
        onSubmit()
      }
    }
  }, [
    isLoading,
    isUninitialized,
    isSuccess,
    data
  ])
  
  function handleFormForChange(e) {
    setFormFor(e.target.value)
    send(e.target.value)
  }

  function handlePhoneChange(number) {
    setState({ ...state, user: { ...state.user, phone: number }})
  }

  function handleChange(e) {
    const { name, value } = e.target
    setState({
      ...state,
      user: {
        ...state.user,
        [name]: value
      }
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    saveAccount({ account: state, authenticity_token })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='py-4'>
        <Col xs={12}>
          <Form.Group>
            <FormCheck
              inline
              type='radio'
              label='Create New'
              name='formFor'
              value='NEW'
              checked={formFor == 'NEW'}
              onChange={handleFormForChange}
            />
            <FormCheck
              inline
              type='radio'
              label='Existing'
              value='EXISTING'
              checked={formFor == 'EXISTING'}
              onChange={handleFormForChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='gy-4'>
        <Col xs={6}>
          <Form.Group>
            <strong>First Name</strong>
            <Form.Control
              name='first_name'
              placeholder='Abc'
              value={state.user?.first_name || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <strong>Last Name</strong>
            <Form.Control
              name='last_name'
              placeholder='Xyz'
              value={state.user?.last_name || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <strong>Phone</strong>
            <PhoneControl
              number={(formFor == 'EXISTING') ? account?.user?.phone : null}
              onChange={handlePhoneChange}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <strong>Email</strong>
            <Form.Control
              type='email'
              name='email'
              placeholder='abc@xyzhousing.com'
              value={state.user?.email || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} className='d-flex justify-content-end'>
          <Button
            className='d-flex align-items-center'
            variant='primary'
            type='submit'
          >
            {isLoading && (
              <Spinner
                className='me-2 text-white'
                animation='border'
                size='sm'
              />
            )}
            <span>Save</span>
          </Button>
        </Col>
        {!!user.edit_url && (
          <Col xs={12}>
            <a href={user.edit_url} target='_blank'>
              <strong>{user.edit_url}</strong>
            </a>
          </Col>
        )}
      </Row>
    </Form>
  )
}