import Base from "./Base";

export default class Google extends Base {

  constructor(data: Record<string, any>) {
    super(data, "google");
  }

  get types(): string[] {
    return this.data.types;
  }

  get formatted_address(): string {
    return this.data.formatted_address;
  }

  get address_components(): string[] {
    return this.data.address_components;
  }

  get geometry(): string[] {
    return this.data.geometry;
  }

  get location_type(): string {
    return this.data.location_type;
  }

  get partial_match(): string {
    return this.data.partial_match;
  }

  get place_id(): string {
    return this.data.place_id;
  }
}