import { assign, createMachine, send, sendTo } from 'xstate'
import { Account } from '../../../../accounts/types'
import { User } from '../../../../users/types'
import { StateProps } from './'
import { Events, EventTypes } from './types'

export interface Context extends StateProps {
  prev?: StateProps;
  idle?: boolean;
  progress?: number;
  readOnly?: boolean;
}

const partialContext = {
  user: null,
  account: null,
  project: null,
  property: null,
  drafting_project: null,
  progress: 0,
  readOnly: false,
}

export const initialContext = {
  ...partialContext,
  prev: partialContext,
  idle: false,
}

const projectProps = [
  'scope_slug',
  'description',
  'plan_unit_ct',
  'plan_constr_type',
  'plan_layout',
  'design_type',
  'listing_price',
  'listing_caprate'
]

export function getContext(context, { account, user, property, project, drafting_project }: StateProps) {
  return {
    account: (account != undefined)
      ? account
      : user?.account || project?.account || drafting_project?.project?.account || context.account || null,
    user: (user != undefined)
      ? user
      : account?.user || project?.account?.user || drafting_project?.project?.account?.user || context.user || null,
    property: (property != undefined)
      ? property
      : project?.property || context.property || null,
    project: (project != undefined)
      ? project
      : drafting_project?.project || context.project || null,
    drafting_project: (drafting_project != undefined)
      ? drafting_project
      : project?.drafting_project || context.drafting_project || null
  }
}

export const machine = createMachine<Context>(
  {
    context: initialContext,
    id: 'account',
    initial: 'account',
    states: {
      account: {
        always: {
          target: 'user',
          cond: 'hasAccount',
          actions: assign({ progress: 0.25 })
        },
        on: {
          NEW: {
            actions: [
              'saveContext',
              'resetContext'
            ]
          },
          EXISTING: {
            actions: [
              'restoreContext'
            ]
          },
          SELECT: {
            target: 'user',
            actions: [
              'resetContext',
              'setContext',
              assign({ progress: 0.25 })
            ]
          },
          RESUME: {
            target: 'user',
            actions: [
              'setContext',
              assign({ progress: (context) => (!context.progress) ? 0.25 : context.progress })
            ]
          },
        }
      },
      user: {
        always: {
          target: 'property',
          cond: 'hasUser',
          actions: assign({ progress: 0.50 })
        },
        on: {
          NEW: {
            actions: [
              'saveUser',
              'resetUser'
            ]
          },
          EXISTING: {
            actions: [
              'restoreUser'
            ]
          },
          RESUME: {
            target: 'property',
            actions: [
              'setContext',
              assign({ progress: (context) => (context.progress == 0.25) ? 0.50 : context.progress })
            ]
          },
        }
      },
      property: {
        always: {
          target: 'project',
          cond: 'hasProperty',
          actions: assign({ progress: 0.75 })
        },
        on: {
          RESUME: {
            target: 'project',
            actions: [
              'setContext',
              assign({ readOnly: true }),
              assign({ progress: ({ project, progress }) => (progress == 0.50)
                ? (!project || (!!project && projectProps.filter(prop => !project[prop]).length == projectProps.length))
                  ? 0.75
                  : (!!project) ? 1 : 0.75
                : progress
              })
            ]
          },
        }
      },
      project: {
        always: {
          target: 'drafting_project',
          cond: 'hasProject',
          actions: assign({ progress: 1 })
        },
        on: {
          RESUME: {
            target: 'drafting_project',
            actions: [
              'setContext',
              assign({ progress: (context) => (context.progress == 0.75) ? 1 : context.progress })
            ]
          },
        }
      },
      drafting_project: {
        on: {
          RESUME: {
            actions: [
              'setContext'
            ]
          },
        }
      }
    },
    on: {
      IDLE: {
        target: 'account',
        actions: ['hardResetContext']
      },
      CONTEXT: {
        actions: ['setContext']
      },
      RESET: {
        actions: ['resetContext']
      },
      ACCOUNT: {
        target: 'account',
        actions: ['pause']
      },
      USER: {
        target: 'user',
        actions: ['pause']
      },
      PROPERTY: {
        target: 'property',
        actions: ['pause']
      },
      PROJECT: {
        target: 'project',
        actions: ['pause']
      },
      DRAFTING_PROJECT: {
        target: 'drafting_project',
        actions: ['pause']
      },
    },
  },
  
  {
    actions: {
      pause: assign({ idle: true }),
      resume: assign({ idle: false }),
      setContext: assign(getContext),
      saveContext: assign({ prev: (context) => context }),
      hardResetContext: assign(initialContext),
      resetContext: assign(partialContext),
      restoreContext: assign({
        account: (context) => context.prev.account,
        user: (context) => context.prev.user,
        property: (context) => context.prev.property,
        project: (context) => context.prev.project,
        drafting_project: (context) => context.prev.drafting_project,
        progress: (context) => context.prev.progress,
      }),
      saveUser: assign({ prev: (context) => ({  user: context.user })}),
      resetUser: assign({ user: partialContext.user }),
      restoreUser: assign({ user: (context) => context.prev.user }),
    },
    guards: {
      hasAccount: (context) => !context.idle && !!context.account?.id,
      hasUser: (context) => !context.idle && !!context.user?.id,
      hasProperty: (context) => !context.idle && !!context.property?.id,
      hasProject: (context) => !context.idle && !!context.project?.id,
      hasDraftingProject: (context) => !context.idle && !!context.drafting_project?.id
    }
  }
) 
