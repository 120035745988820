import { css } from "styled-components";

export interface StyleProps {
  className?: string;
  width?: number;
  height?: number;
  centered?: boolean;
  children?: any;
}

const header = css`
  font-family: 'Graphik-Semibold';
  font-size: 1.15rem;
  font-weight: 700;
  color: ${props => props.theme.gray};
  margin-bottom: 0;
`;

const subtitle = css`
  @media (max-width: 575.98px) {
    font-size: 0.8rem !important;
  }
  @media (min-width: 576px) and (max-width: 991.98px) {
    font-size: 1.1rem !important;
  }
`;

const button = css`
  background-color: ${props => props.theme.blue};
  border-radius: .5rem !important;
  text-transform: none;
  padding-left: 15px;
  padding-right: 15px;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: ${props => props.theme.blue} !important;
  }

  &:focus {
    border-color: ${props => props.theme.blue};
    box-shadow: 0 0 0 .2rem rgba(240, 192, 152, 0.5);
  }
`;

const content = css`
  @media (max-width: 575.98px) {
    font-size: 0.8rem !important;
  }
`;

const loadingIndicator = css`
  -webkit-animation: rotation 1s infinite linear;
  -moz-animation: rotation 1s infinite linear;
  -o-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
  border-left: 6px solid rgba(19, 152, 255, 0.15);
  border-right: 6px solid rgba(19, 152, 255, 0.15);
  border-bottom: 6px solid rgba(19, 152, 255, 0.15);
  border-top: 6px solid rgba(19, 152, 255, 0.8);
  border-radius: 100%;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const draggable = css`
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

const noDrag = css`
  user-drag: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
  -ms-user-drag: none;
  -khtml-user-drag: none;
  -o-user-drag: none;
`;

const noSelect = css`
  user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -o-user-select;
`;

export {
  header,
  button,
  subtitle,
  content,
  loadingIndicator,
  draggable,
  noDrag,
  noSelect
};