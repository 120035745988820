import React from 'react'
import styled, { StyledComponent } from 'styled-components'

const Wrapper: StyledComponent = styled.div`
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0
    }
  }

  @keyframes scale {

    0%,
    100% {
      transform: none
    }

    50% {
      transform: scale3d(1.1, 1.1, 1)
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #28a745
    }
  }
`

const Checkmark: StyledComponent = styled.svg`
  display:inherit;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #28a745;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
`
const Circle: StyledComponent = styled.circle`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #28a745;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`

const Check: StyledComponent = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`

export interface SuccessIndicatorProps {
  className?: string;
}

export default function SuccessIndicator({ className }: SuccessIndicatorProps) {

  return (
    <Wrapper className={className}>
      <Checkmark xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
        <Circle cx='26' cy='26' r='25' fill='none' />
        <Check fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      </Checkmark>
    </Wrapper>
  )
}