import { PlanLayouts } from '../calculator/types'
import { DesignTypes, DesignTypeLabels, DesignTypeUnits, AduScores, AduScoreValues, AduScoreLabels } from '@application/types'

export const PlanLayoutLables = {
  [PlanLayouts.Small]: '1-bed/1-bath',
  [PlanLayouts.Medium]: '2-bed/1-bath',
  [PlanLayouts.Large]: '3-bed/1-bath'
}

export const PlanLayoutParams = {
  [PlanLayouts.Small]: {
    sqft_min: 350,
    sqft_max: 450
  },
  [PlanLayouts.Medium]: {
    sqft_min: 550,
    sqft_max: 650
  },
  [PlanLayouts.Large]: {
    sqft_min: 750,
    sqft_max: 850
  },
}

export enum Settings {
  Profile='profile',
  Property='property',
  Account='account'
}

export enum Statuses {
  Approved='approved',
  Declined='declined'
}

export const StatusLabels = {
  [Statuses.Approved]: 'Approved',
  [Statuses.Declined]: 'Declined'
}

export {
  DesignTypes,
  DesignTypeLabels,
  DesignTypeUnits,
  AduScores,
  AduScoreValues,
  AduScoreLabels
}