import { PlanTypes, PlanTypeLabels, PlanLayouts, PlanLayoutLabels, PlanLayoutBeds, PlanLayoutSqft } from '@application/types'

export enum Variants {
  PlanUnits='plan_units',
  PlanType='plan_type',
  PlanLayout='plan_layout'
}

export {
  PlanTypes,
  PlanTypeLabels,
  PlanLayouts,
  PlanLayoutLabels,
  PlanLayoutBeds,
  PlanLayoutSqft
}