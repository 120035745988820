import Base from "./Base";
import { Loader } from "@googlemaps/js-api-loader";
import Result from "../results/Google";

export default class Google extends Base {

  constructor() {
    super("google");
    
    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places", "geometry", "drawing"]
    });

    this.process = this.process.bind(this);
    loader.load().then(this.process);
  }

  process(google: any): void {
    Promise.all(this.inputs.map(this.initAutocomplete(google)));
  }

  initAutocomplete(google: any): (input: HTMLInputElement) => void {
    return (input: HTMLInputElement) => {
      input.addEventListener("focus", () => {
        const target = document.getElementById(input.dataset.addressSearchFor) as HTMLInputElement;
        
        if (!target) {
          return;
        }
  
        const autocomplete = new google.maps.places.Autocomplete(input, {types: ["address"]});
        autocomplete.addListener("place_changed", () => {
          const event = new CustomEvent("place_changed");
          input.dispatchEvent(event);

          const place = autocomplete.getPlace();
          const result = new Result(place);
          target.value = result.encode();
        });
      });
    };
  }
}