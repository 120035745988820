import React, { useContext } from 'react'
import { Modal, Nav } from 'react-bootstrap'
import styled, { StyledComponent } from 'styled-components'
import { useActor, useSelector } from '@xstate/react'
import { ModalProps } from './'
import Account from './Account'
import Context from './Context'
import DraftingProject from './DraftingProject'
import Match from './Match'
import Project from './Project'
import Property from './Property'
import User from './User'

const NavLink: StyledComponent = styled(Nav.Link)`
  margin-bottom: 0 !important;
`

const selectProgress = (state) => state.context.progress

export default function ModalBody({ onSubmit }: ModalProps) {

  const { service } = useContext(Context)
  const progress = useSelector(service, selectProgress)
  const [state, send] = useActor(service)

  return (
    <Modal.Body>
      <Nav variant="tabs">
        <Nav.Item className='border-bottom border-primary border-2'>
          <NavLink
            eventKey='account'
            onClick={() => send({ type: 'ACCOUNT' })}
            active={state.matches('account')}
          >
            Account
          </NavLink>
        </Nav.Item>
        <Nav.Item className={(progress >= 0.25) ? 'border-bottom border-primary border-2' : ''}>
          <NavLink
            eventKey='user'
            onClick={() => send({ type: 'USER' })}
            active={state.matches('user')}
            disabled={progress < 0.25}
          >
            User
          </NavLink>
        </Nav.Item>
        <Nav.Item className={(progress >= 0.50) ? 'border-bottom border-primary border-2' : ''}>
          <NavLink
            eventKey='property'
            onClick={() => send({ type: 'PROPERTY' })}
            active={state.matches('property')}
            disabled={progress < 0.50}
          >
            Property
          </NavLink>
        </Nav.Item>
        <Nav.Item className={(progress >= 0.75) ? 'border-bottom border-primary border-2' : ''}>
          <NavLink
            eventKey='project'
            onClick={() => send({ type: 'PROJECT' })}
            active={state.matches('project')}
            disabled={progress < 0.75}
          >
            Project
          </NavLink>
        </Nav.Item>
        <Nav.Item className={(progress >= 1) ? 'border-bottom border-primary border-2' : ''}>
          <NavLink
            eventKey='drafting_project'
            onClick={() => send({ type: 'DRAFTING_PROJECT' })}
            active={state.matches('drafting_project')}
            disabled={progress < 1}
          >
            Drafting Project
          </NavLink>
        </Nav.Item>
      </Nav>
      <Match state='account'>
        <Account onSubmit={onSubmit} />
      </Match>
      <Match state='user'>
        <User onSubmit={onSubmit} />
      </Match>
      <Match state='property'>
        <Property onSubmit={onSubmit} />
      </Match>
      <Match state='project'>
        <Project onSubmit={onSubmit} />
      </Match>
      <Match state='drafting_project'>
        <DraftingProject onSubmit={onSubmit} />
      </Match>
    </Modal.Body>
  )
}