import variables from "../scss/bootstrap/v5/_variables.scss";

const {
  "grid-breakpoint-xs": xs,
  "grid-breakpoint-sm": sm,
  "grid-breakpoint-md": md,
  "grid-breakpoint-lg": lg,
  "grid-breakpoint-xl": xl,
  "grid-breakpoint-xxl": xxl,
} = variables;

const breakpoints = { xs, sm, md, lg, xl, xxl };

const theme = {
  black:           "#000000",
  dark:            "#3c4557",  
  lightBlue:       "#d0eaff",
  grayBlue:        "#C0CEDE",
  blue:            "#0085FF",
  babyBlue:        "#0085FF",
  indigo:          "#6610f2",
  purple:          "#6f42c1",
  pink:            "#d63384",
  red:             "#dc3545",
  orange:          "#ff8a00",
  yellow:          "#ffc107",
  green:           "#1ab759",
  teal:            "#20c997",
  cyan:            "#0dcaf0",
  lightGray:       "#dfe7ed",
  darkGray:        "#A4B2C4",
  gray:            "#7f8694",
  silver:          "#eeeeee",
  white:           "#ffffff",
  offWhite:        "#F7FAFC",
  blueGray:        "#D4DDEA",
  lightBlueDarker: "#E3E8F0",
  textGray:        "#7f8c9f",
  grayDarker:      "#5b667a",
  field:           "#f7fafc",
  fieldBorder:     "#d4ddea",
  breakpointMin(name) {
    const min = breakpoints[name];
    return min || null;
  },
  breakpointMax(name) {
    const max = breakpoints[name];
    return (max && max > 0) ? max - 0.02 : null;
  },
  mediaBreakpointUp(name, content) {
    const min = this.breakpointMin(name);
    return `@media (min-width: ${min || name}) {
      ${content}
    }`
    return content;
  },
  mediaBreakpointDown(name, content) {
    const max = this.breakpointMax(name);
    return `@media (max-width: ${max || name}) {
      ${content}
    }`
    return content;
  }
};

export default theme;