import React, { useEffect } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { useActor, useInterpret } from '@xstate/react'
import { ModalTitle } from '../../../../../styles/ModalTitle'
import { Account as IAccount } from '../../../../accounts/types'
import AccountModal from '../../../../Modal'
import {
  DraftingProject as IDraftingProject, Project as IProject,
  Property as IProperty
} from '../../../../projects/types'
import { User as IUser } from '../../../../users/types'
import Context from './Context'
import { getContext, machine } from './machine'
import ModalBody from './ModalBody'

export interface StateProps {
  account?: IAccount;
  user?: IUser;
  property?: IProperty;
  project?: IProject;
  drafting_project?: IDraftingProject;
  progress?: number;
}

export interface ModalProps extends StateProps {
  id?: string;
  show?: boolean;
  onHide?: () => void;
  onSubmit?: () => void;
  authenticity_token?: string;
  className?: string;
}

export default function Modal({ id, show, authenticity_token, onHide, onSubmit, ...machineContext }: ModalProps) {

  const service = useInterpret(machine)
  const [state] = useActor(service)

  useEffect(() => {
    service.stop()
    service.start(service.initialState)
    service.send('CONTEXT', machineContext)
  }, [])

  useDeepCompareEffect(() => {
    if (distinctUntilChanged()) {
      service.send({ type: 'IDLE' })
      service.send('CONTEXT', machineContext)
    }
  }, [state.context, machineContext])

  function distinctUntilChanged() {
    const prev = state.context
    const curr = getContext(state.context, machineContext as StateProps)
    return prev?.account?.id != curr?.account?.id
      || prev?.user?.id != curr?.user?.id
      || prev?.property?.id != curr?.property?.id
      || prev?.project?.id != curr?.project?.id
      || prev?.drafting_project?.id != curr?.drafting_project?.id
  }

  function handleHide() {
    if (onHide) {
      onHide()
    }
    setTimeout(() => {
      service.send('RESET')
      service.send('ACCOUNT')
    }, 500)
  }

  return (
    <Context.Provider value={{
      service,
      authenticity_token
    }} >
      <AccountModal
        id={id}
        size='lg'
        className='shadow-none'
        onHide={handleHide}
        { ...(show != undefined && { show }) }
      >
        <AccountModal.Header closeButton>
          <ModalTitle>
            Setup
          </ModalTitle>
        </AccountModal.Header>
        <ModalBody onSubmit={onSubmit} />
      </AccountModal>
    </Context.Provider>
  )
}