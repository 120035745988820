import React, { useContext, useState, useEffect, ChangeEvent } from 'react'
import { Account as IAccount } from '../../../../accounts/types'
import { Project as IProject, DraftingProject as IDraftingProject } from '../../../../projects/types'
import { Row, Col, Form, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector, useActor } from '@xstate/react'
import Context from './Context'
import FormCheck from '../../../../../styles/FormCheck'
import { useUpdateProjectMutation } from '../../slices/api'
import { useDeepCompareEffect } from 'react-use'
import { ModalProps } from './'

const selectAccount = (state) => state.context.account
const selectProject = (state) => state.context.project
const selectDraftingProject = (state) => state.context.drafting_project

export default function DraftingProject({ onSubmit }: ModalProps) {
  const formatter = new Intl.NumberFormat()
  const { service, authenticity_token } = useContext(Context)
  const account = useSelector(service, selectAccount)
  const project = useSelector(service, selectProject)
  const drafting_project = useSelector(service, selectDraftingProject) || { notes: null }
  const [current, send] = useActor(service)
  const [state, setState] = useState<IDraftingProject>(drafting_project)

  const [updateProject, {
    isLoading,
    isUninitialized,
    isSuccess,
    data,
    reset
  }] = useUpdateProjectMutation()

  useDeepCompareEffect(() => {
    setState(drafting_project)
  }, [drafting_project])

  useEffect(() => {
    if (!isLoading && !isUninitialized && isSuccess && data) {
      send({ type: 'RESUME', drafting_project: data.drafting_project })
      reset()
      if (onSubmit) {
        onSubmit()
      }
    }
  }, [
    isLoading,
    isUninitialized,
    isSuccess,
    data
  ])

  function handleChange(e) {
    const { name, value } = e.target
    setState({
      ...state,
      [name]: value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateProject({
      account,
      project: {
        ...project,
        drafting_project: state
      },
      authenticity_token
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='py-4 gy-3'>
        <Col xs={12}>
          <Form.Group>
            <strong>Notes</strong>
            <Form.Control
              name='notes'
              value={state.notes || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} className='d-flex justify-content-end'>
          <Button
            className='d-flex align-items-center'
            variant='primary'
            type='submit'
          >
            {isLoading && (
              <Spinner
                className='me-2 text-white'
                animation='border'
                size='sm'
              />
            )}
            <span>Save</span>
          </Button>
        </Col>
        {!!drafting_project.edit_url && (
          <Col xs={12}>
            <a href={drafting_project.edit_url} target='_blank'>
              <strong>{drafting_project.edit_url}</strong>
            </a>
          </Col>
        )}
      </Row>
    </Form>
  )
}