import React, { useContext, ReactElement, useEffect } from 'react'
import { machine } from './machine'
import { useMachine, useActor } from '@xstate/react';
import Context from './Context'


export interface MatchProps {
  children?: ReactElement | ReactElement[] | string | number;
  state?: string | string[];
}

export default function Match({ children, state }: MatchProps) {
  const context = useContext(Context)
  const [current] = useActor(context.service)
  const expected = Array.isArray(state) ? state : [state]

  useEffect(() => {
    expected.some(value => current.matches(value)) 
  })

  return expected.some(value => current.matches(value))
    ? <>{children}</>
    : null
}