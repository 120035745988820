import React, { useState, useEffect, ChangeEvent, forwardRef, MutableRefObject, useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export interface PercentControlProps {
  name?: string;
  placeholder?: string;
  value?: number;
  onChange?: (value: number) => void;
}

const PercentControl = forwardRef(({ name, placeholder, value, onChange }: PercentControlProps, ref: MutableRefObject<HTMLDivElement>) => {
  
  const controlRef = useRef(null)
  const [state, setState] = useState<string>(parseFloat((value * 100).toFixed(2)).toString())

  useEffect(() => {
    if (value) {
      setState(parseFloat((value * 100).toFixed(2)).toString())
    }
  }, [value])

  useEffect(() => {
    const val = parseFloat((parseFloat(state) / 100).toFixed(4))
    if (!isNaN(val)) {
      return
    }
    const delayDebounceFn = setTimeout(() => {
      setState('')
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [state])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let { value: val } = e.target
    setState(val)
    const n = parseFloat((parseFloat(val) / 100).toFixed(4))
    if (onChange) {
      onChange((!isNaN(n)) ? n : 0)
    }
  }

  function handleKeyDown(e) {
    if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault()
    }
  }

  function handleClick(e) {
    const pos = (state?.length) ? state.length * 2 : 0
    controlRef.current.setSelectionRange(pos, pos)
  }

  return (
    <InputGroup ref={ref}>
      <Form.Control
        ref={controlRef}
        name={name}
        placeholder={placeholder}
        value={state || ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        aria-describedby="percent-addon"
      />
      <InputGroup.Text id="percent-addon">%</InputGroup.Text>
    </InputGroup>
  )
})

export default PercentControl