import React from "react";
import styled from "styled-components";
import { header, StyleProps } from "./";

let ModalTitle: React.FC<StyleProps> = ({ className, children }: StyleProps) => {
  return (
    <h6 className={className}>
      {children}
    </h6>
  );
};

ModalTitle = styled(ModalTitle)`
  ${header}
`;

export {
  ModalTitle
};