import React, { useContext, useState, useEffect, ChangeEvent } from 'react'
import { Account as IAccount } from '../../../../accounts/types'
import { Project as IProject } from '../../../../projects/types'
import { Row, Col, Form, Button, Spinner, InputGroup } from 'react-bootstrap'
import { useSelector, useActor } from '@xstate/react'
import Context from './Context'
import FormCheck from '../../../../../styles/FormCheck'
import { useUpdateProjectMutation } from '../../slices/api'
import { useDeepCompareEffect } from 'react-use'
import PhoneControl from '../../../../application/PhoneControl'
import { PlanTypes, PlanTypeLabels, PlanLayouts, PlanLayoutLabels } from '../../../../projects/calculator/types'
import { DesignTypes, DesignTypeLabels } from '../../../../projects/types'
import MoneyControl from '../../../../application/MoneyControl'
import PercentControl from '../../../../application/PercentControl'
import { Basic as BasicEditor } from '../../../../tinymce'
import { ModalProps } from './'

const selectAccount = (state) => state.context.account
const selectProperty = (state) => state.context.property
const selectProject = (state) => state.context.project

export default function Project({ onSubmit }: ModalProps) {
  const formatter = new Intl.NumberFormat()
  const { service, authenticity_token } = useContext(Context)
  const account = useSelector(service, selectAccount)
  const property = useSelector(service, selectProperty)
  const project = useSelector(service, selectProject) || {
    scope_slug: null,
    description: null,
    plan_unit_ct: null,
    plan_constr_type: null,
    plan_layout: null,
    design_type: null,
    listing_price: null,
    listing_caprate: null,
    is_public: false
  }
  const [current, send] = useActor(service)
  const [state, setState] = useState<IProject>(project)

  const [updateProject, {
    isLoading,
    isUninitialized,
    isSuccess,
    data,
    reset
  }] = useUpdateProjectMutation()

  useDeepCompareEffect(() => {
    setState(project)
  }, [project])

  useEffect(() => {
    if (!isLoading && !isUninitialized && isSuccess && data) {
      send({ type: 'RESUME', project: {
        ...state,
        ...data
      }})
      reset()
      if (onSubmit) {
        onSubmit()
      }
    }
  }, [
    isLoading,
    isUninitialized,
    isSuccess,
    data
  ])

  function handleDescriptionChange(a) {
    setState({
      ...state,
      description: a
    })
  }

  function handlePriceChange(value) {
    setState({
      ...state,
      listing_price: value
    })
  }

  function handleCaprateChange(value) {
    setState({
      ...state,
      listing_caprate: value
    })
  }

  function handleChange(e) {
    const { name, value } = e.target
    setState({
      ...state,
      [name]: value
    })
  }

  function handleSelect(e: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target
    setState({
      ...state,
      [name]: value
    })
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateProject({
      account,
      project: state,
      authenticity_token
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='py-4 gy-3'>
        <Col xs={12}>
          <Form.Group>
            <strong>Scope Slug</strong>
            <Form.Control
              name='scope_slug'
              placeholder='5e_2p_det_1bd1ba'
              value={state.scope_slug || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group>
            <strong>Description</strong>
            <BasicEditor
              height={250}
              value={state.description || ''}
              onChange={handleDescriptionChange}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Plan Unit Ct</strong>
            <Form.Control
              type='number'
              name='plan_unit_ct'
              value={state.plan_unit_ct || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Construction Type</strong>
            <Form.Select
              name='plan_constr_type'
              value={state.plan_constr_type || ""}
              onChange={handleSelect}
            >
              <option></option>
              {Object.keys(PlanTypes).map((key, i) => (
                <option key={`type-${i}`} value={PlanTypes[key]}>
                  {PlanTypeLabels[PlanTypes[key]]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Plan Layout</strong>
            <Form.Select
              name='plan_layout'
              value={state.plan_layout || ""}
              onChange={handleSelect}
            >
              <option></option>
              {Object.keys(PlanLayouts).map((key, i) => (
                <option key={`type-${i}`} value={PlanLayouts[key]}>
                  {PlanLayoutLabels[PlanLayouts[key]]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Design Type</strong>
            <Form.Select
              name='design_type'
              value={state.design_type || ""}
              onChange={handleSelect}
            >
              <option></option>
              {Object.keys(DesignTypes).map((key, i) => (
                <option key={`type-${i}`} value={DesignTypes[key]}>
                  {DesignTypeLabels[DesignTypes[key]]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Listing Price</strong>
            <MoneyControl
              name="listing_price"
              placeholder="900,000"
              value={state.listing_price || 0}
              onChange={handlePriceChange}
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group>
            <strong>Caprate</strong>
            <PercentControl
              name="listing_caprate"
              placeholder="6"
              value={state.listing_caprate || 0}
              onChange={handleCaprateChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} className='d-flex justify-content-end'>
          <Button
            className='d-flex align-items-center'
            variant='primary'
            type='submit'
          >
            {isLoading && (
              <Spinner
                className='me-2 text-white'
                animation='border'
                size='sm'
              />
            )}
            <span>Save</span>
          </Button>
        </Col>
        {!!project.public_url && (
          <Col xs={12}>
            <a href={project.public_url} target='_blank'>
              <strong>{project.public_url}</strong>
            </a>
          </Col>
        )}
      </Row>
    </Form>
  )
}