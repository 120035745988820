import React, { forwardRef, MutableRefObject} from "react";
import styled from "styled-components";
import { loadingIndicator, StyleProps } from "./";

let LoadingIndicator = forwardRef(({ className }: StyleProps, ref: MutableRefObject<HTMLDivElement>) => {
  return (
    <div className={className} ref={ref} />
  );
});

LoadingIndicator = styled(LoadingIndicator)`
  ${loadingIndicator}
  ${props => (props.width != undefined) ? `
    width: ${props.width}px;` : `
    width: 100px;
    @media (max-width: 991.98px) {
      width: 70px;
    }
  `}
  ${props => (props.height != undefined) ? `
    height: ${props.height}px;` : `
    height: 100px;
    @media (max-width: 991.98px) {
      height: 70px;
    }
  `}
  ${props => (props.centered == undefined || !!props.centered) && `
    margin: 0 auto;
  `}
`;

export default LoadingIndicator;