import { useState, useEffect } from 'react'

export default function useFormattedPhone(phone: string) {

  const [formatted, setFormatted] = useState<string>(null)
  const [parts, setParts] = useState<string[]>([null, null, null])

  useEffect(() => {
    if (phone?.length) {
      const raw = phone.replace(/\D/g,'').slice(-10)
      const split = raw.replace(/^(\d{3})(\d{3})(\d+)$/, "$1-$2-$3").split('-')
      setFormatted(raw.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"))
      setParts((split?.length == 3) ? split : [null, null, null])
    }
    else {
      setFormatted(null)
      setParts([null, null, null])
    }
  }, [phone])

  return {
    formatted,
    parts
  }
}